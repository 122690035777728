import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserPageLocationState } from '@user/pages/UserPageLocationState';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { FlatButton, Loading } from '@framework/ui/atoms';
import { GroupMemberInvitation, GroupMemberInvitationOperation } from '@group/domain';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { UserId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { useActionLogSender } from '@framework/action-log';
import { UserKey } from '@user/domain';
import { createOnboardingViewModel } from './createOnboardingViewModel';

type Props = {
    currentUserId: UserId;
    invitation: GroupMemberInvitation;
};

export const AuthenticatedMenu: React.FC<Props> = ({ currentUserId, invitation }: Props) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const location = useLocation<UserPageLocationState>();
    const actionLogSender = useActionLogSender();

    const handleInvitationAccept = useCallback(async () => {
        setLoading(true);
        const result = await GroupMemberInvitationOperation.inviteUser(currentUserId, invitation);
        let personalWorkspaceId: WorkspaceId | null = null;
        let onboardingViewModelId: ViewModelId | null = null;
        if (result.ok) {
            // 招待されたプライベートワークスペースがあれば参加する
            await WorkspaceOperation.joinInvitedWorkspaces(invitation.groupId, currentUserId, invitation.workspaces);
            // グループ内公開ワークスペースに参加する
            await WorkspaceOperation.joinInternalPublicWorkspaces(invitation.groupId, currentUserId);
            // パーソナルワークスペースを作成する
            personalWorkspaceId = await WorkspaceOperation.createPersonal(invitation.groupId, currentUserId);
        }
        setLoading(false);

        if (result.ok) {
            // オンボーディング用ビューモデルを作成する
            if (personalWorkspaceId) {
                onboardingViewModelId = await createOnboardingViewModel(
                    invitation.groupId,
                    UserKey.buildFromId(currentUserId),
                    personalWorkspaceId,
                    actionLogSender
                );
            }

            if (onboardingViewModelId) {
                history.push(UserPagePathBuilder.viewModelPage(onboardingViewModelId));
                return;
            }
            history.push(UserPagePathBuilder.groupPage(invitation.groupId));
        } else {
            setErrorMessage(
                result.message ??
                    'グループへの参加に失敗しました。少し時間を空けてからもう一度試してみるか、サポート担当者にお問い合わせください。'
            );
        }
    }, [currentUserId, history, invitation, actionLogSender]);

    // 外部認証プロバイダを利用した新規ユーザ登録の完了後にこの画面に戻ってきた場合には、
    // 自動で招待の受諾処理を行う。
    useEffect(() => {
        if (location.state?.signUpFinished) {
            handleInvitationAccept();
        }
    }, [handleInvitationAccept, location]);

    return (
        <>
            <div className="py-4">
                {loading ? (
                    <Loading />
                ) : (
                    !errorMessage && (
                        <FlatButton color="brand" className="px-8" onClick={handleInvitationAccept}>
                            グループに参加する
                        </FlatButton>
                    )
                )}
            </div>

            {errorMessage && <span className="text-red-700">{errorMessage}</span>}
        </>
    );
};
