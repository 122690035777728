import { GroupEntity, GroupLicense, GroupMemberRole } from '@group/domain';
import { BasicRoleSelect, GroupConsoleTitle } from '../common';
import { Button } from '@framework/ui/atoms/Button';
import { useCallback, useMemo, useState } from 'react';
import { EmailParseResult } from './EmailAddressParser';
import { createGroupMemberInvitations, MAX_INVITATION_COUNT } from './createGroupMemberInvitations';
import { SignUpMethodName } from '@group/domain/GroupEntity';
import { MemberInvitationEmailInput } from './MemberInvitationEmailInput';
import { useCurrentUserEntity } from '@user/UserEntity';
import { toast } from 'react-hot-toast';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { GroupLicenseJSON } from '@schema-app/group/licenses/{groupId}/GroupLicenseJSON';
import { useAssignedWorkspaces } from '@workspace/ui/WorkspaceList';
import { InvitePageWorkspaceList, SelectedWorkspaceItem } from './InvitePageWorkspaceList';

type Props = {
    group: GroupEntity;
};

export const GroupConsoleInvitePage: React.FC<Props> = ({ group }: Props) => {
    const currentUserEntity = useCurrentUserEntity();
    const workspaces = useAssignedWorkspaces(currentUserEntity?.id || null, group.id);
    const [emails, setEmails] = useState<EmailParseResult[]>([]);
    const [role, setRole] = useState<GroupMemberRole>('member');
    const [selectedWorkspaces, setSelectedWorkspaces] = useState<SelectedWorkspaceItem[]>([]);
    const [message, setMessage] = useState<string>('');
    const [sending, setSending] = useState(false);
    const hasInvalidAddress = useMemo(() => emails.some(({ valid }) => !valid), [emails]);
    const [groupLicense, { loading: groupLicenseIsLoading }] = useGroupLicenseSnapshot(group.id);
    const [numberOfMembers, { loading: numberOfMembersIsLoading }] = useSnapshot({
        path: RTDBPath.Group.memberRolesPath(group.id),
        load: ({ snapshot }) => {
            return snapshot.numChildren();
        },
    });

    const availableInvitationCount = useMemo(() => {
        if (typeof numberOfMembers !== 'number') {
            return Infinity;
        }
        return GroupLicense.getAvailableInvitationCount({
            groupLicense: groupLicense ?? undefined,
            memberCount: numberOfMembers,
        });
    }, [groupLicense, numberOfMembers]);

    const sendable =
        emails.length > 0 &&
        emails.length <= MAX_INVITATION_COUNT &&
        !hasInvalidAddress &&
        !groupLicenseIsLoading &&
        !numberOfMembersIsLoading;

    const filteredWorkspaces = useMemo(() => {
        if (workspaces) {
            return workspaces.filter((ws) => !ws.isPersonal && !ws.setting.isPublicSpace);
        } else {
            return [];
        }
    }, [workspaces]);

    const availableSignUpMethods = useMemo(() => group.setting.availableSignUpMethods, [group]);

    const handleAddEmails = useCallback((addedEmails: EmailParseResult[]) => {
        setEmails((emails) => [...emails, ...addedEmails]);
    }, []);

    const handleRemoveEmail = useCallback((removeEmail: string) => {
        setEmails((emails) => emails.filter(({ email }) => email != removeEmail));
    }, []);

    const handleRemoveInvalidEmails = useCallback(() => {
        setEmails((emails) => emails.filter(({ valid }) => valid));
    }, []);

    const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }, []);

    const handleWorkspaceChange = useCallback((workspaces: SelectedWorkspaceItem[]) => {
        setSelectedWorkspaces(workspaces);
    }, []);

    const handleInvite = useCallback(async () => {
        if (!currentUserEntity) {
            alert('送信者情報がないため、招待を行うことはできません。');
            return;
        }

        if (availableInvitationCount < emails.length) {
            alert(
                `購入グループメンバー数の上限に達しているため（残数${availableInvitationCount}）、これ以上招待ができません。追加ご希望の際は、ヘルプセンターにあるお問い合わせフォームよりご連絡ください。`
            );
            return;
        }

        if (!sendable) {
            alert('不正なメールアドレスが含まれているか件数が多すぎるため、招待を行うことはできません。');
            return;
        }

        setSending(true);

        const emailAddresses = emails.map(({ email }) => email);
        // メッセージに改行があれば改行タグに置換
        const convertedMessage = message.replace(/\n/g, '<br>');
        if (
            await createGroupMemberInvitations(
                group,
                currentUserEntity,
                emailAddresses,
                role,
                selectedWorkspaces,
                convertedMessage
            )
        ) {
            setEmails([]);
            setRole('member');
            setMessage('');
            setSelectedWorkspaces([]);
            setSending(false);

            toast.success('招待を送信しました');
        } else {
            alert('グループメンバーの招待に失敗しました。もう一度試してみるか、サポート担当者にお問合せください。');
        }
    }, [currentUserEntity, availableInvitationCount, emails, sendable, message, group, role, selectedWorkspaces]);

    return (
        <div className="flex max-w-156 flex-col">
            <GroupConsoleTitle title="招待" />
            <div className="mb-4">
                <div className="mb-2 font-bold">送付先メールアドレス</div>
                <MemberInvitationEmailInput
                    emails={emails}
                    disabled={sending}
                    onAddEmails={handleAddEmails}
                    onRemoveEmail={handleRemoveEmail}
                    onRemoveInvalidEmails={handleRemoveInvalidEmails}
                />
            </div>
            <div className="mb-4">
                <div className="mb-2 font-bold">ロール</div>
                <BasicRoleSelect defaultRole="member" onChange={setRole} disabled={sending} />
            </div>
            <div className="mb-4">
                <div className="mb-2 font-bold">招待メッセージ(オプション)</div>
                <textarea
                    value={message}
                    onChange={handleChangeMessage}
                    className="w-full rounded-sm border border-gray-500 p-2"
                    rows={5}
                    disabled={sending}
                    placeholder="メッセージを入力"
                />
            </div>
            <div className="mb-4">
                ※招待メールにはあなたの名前とメールアドレスが記載されます。
                <br />
                ※招待された方がユーザ登録時に利用可能なログイン方法は次のとおりです。
                <ul className="list-inside list-disc px-6">
                    {SignUpMethodName.values().map((name) => (
                        <li key={name}>
                            {SignUpMethodName.getLocalString(name)} (
                            {availableSignUpMethods.availableOf(name) ? '利用可能' : '利用不可'})
                        </li>
                    ))}
                </ul>
            </div>

            <div className="mb-4">
                <div className="mb-2 font-bold">追加ワークスペース(オプション)</div>
                <InvitePageWorkspaceList
                    workspaces={filteredWorkspaces}
                    selectedWorkspaces={selectedWorkspaces}
                    onChange={handleWorkspaceChange}
                />
            </div>

            <Button
                color="brand"
                className="ml-auto disabled:cursor-not-allowed"
                onClick={handleInvite}
                disabled={!sendable}
            >
                招待を送信する
            </Button>
        </div>
    );
};

function useGroupLicenseSnapshot(groupId: string) {
    return useSnapshot({
        path: RTDBPath.Group.groupLicensePath(groupId),
        load: ({ snapshot }) => {
            // NOTE: グループライセンス情報が存在しない場合も考慮するが、グループライセンス情報の登録を必須にした後はエラーにする
            const value = snapshot.val() as GroupLicenseJSON | null;
            return value ? GroupLicense.load(value) : null;
        },
    });
}
